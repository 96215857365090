@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: 'HelviticaNeue';
  src: url('../src/Assets/fonts/HelveticaNeueRegular.otf') format('opentype');
}
@font-face {
  font-family: 'MYRIADPROREGULAR';
  src: url('../src/Assets/fonts/MYRIADPROREGULAR.OTF') format('opentype');
}
@font-face {
  font-family: 'STHeiti';
  src: url('../src/Assets/fonts/STHEITI.TTF') format('truetype');
}
@font-face {
  font-family: 'STHeitiMedium';
  src: url('../src/Assets/fonts/STHeitiTCMedium.ttf') format('truetype');
}

@layer utilities {
  /* Personaliza la barra de desplazamiento */
  .scrollbar {
    scrollbar-width: thin; /* Cambia el ancho de la barra de desplazamiento */
    scrollbar-color: #3b82f6 #f3f4f6; /* Color de la barra y fondo (azul y gris claro) */
  }

  /* Para navegadores WebKit (Chrome, Safari) */
  .scrollbar::-webkit-scrollbar {
    width: 8px; /* Ancho de la barra de desplazamiento */
    height: 8px; /* Altura de la barra de desplazamiento horizontal */
  }

  .scrollbar::-webkit-scrollbar-track {
    background: #f3f4f6; /* Color de fondo de la pista */
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background-color: #3b82f6; /* Color de la barra de desplazamiento */
    border-radius: 10px; /* Esquinas redondeadas de la barra de desplazamiento */
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #2563eb; /* Color de la barra al pasar el mouse */
  }
}

body{
  background-image: url('./Assets/images/fondoBolsa.jpg');
  background-size: cover;
}
